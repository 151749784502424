import React, { useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container, Row, Col } from '../components/grid'
import PageHeader from '../components/page-header'
import styled, { Box, css, up } from '@xstyled/styled-components'
import { Text, Emphasis } from '../components/text'
import SubHeader from '../components/sub-header'
import Button from '../components/button'
import hvCircle from '../images/hv-circle-bg.svg'
import * as Yup from 'yup'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import Select from '../components/select'

const Error = styled(Text)(
  () => css`
    color: red;
    font-size: 1rem;
  `
)

const HvCircleBox = styled(Box)`
  ${up(
    'xl',
    css`
      padding-bottom: 6;
      background-image: url(${hvCircle});
      background-repeat: no-repeat;
      background-position: calc(100% + 100px) -50px;
    `
  )}
`

const SubmitBox = styled(Box)`
  margin: 5 0;
`

const Label = styled.label(
  () => css`
    color: body;
    font-size: 1rem;
    display: block;
    margin-bottom: 0.5rem;
  `
)

const Input = styled.input(
  () => css`
    font-size: 19px;
    margin-bottom: 8px;
    padding: 1rem;
    background-color: inputGray;
    display: block;
    width: 100%;
    border-width: 0;
    border-style: none;
  `
)

const TextArea = styled.textarea(
  () => css`
    font-size: 19px;
    margin-bottom: 8px;
    padding: 1rem;
    background-color: inputGray;
    display: block;
    width: 100%;
    border-width: 0;
    border-style: none;
  `
)

const FormGroup = styled.box(
  () => css`
    margin-bottom: 1.5rem;
  `
)

const ContactUs = () => (
  <>
    <SEO title="Contact Us" />
    <Layout>
      <PageHeader>Contact Us</PageHeader>

      <HvCircleBox pt="5">
        <Container>
          <Row>
            <Col
              col={{ lg: 3 / 4, xl: 6 / 12 }}
              textAlign="left"
            >
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </HvCircleBox>
    </Layout>
  </>
)

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false)
  const onSubmit = async (values) => {
    try {
      await fetch(process.env.GATSBY_CONTACT_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
      setSubmitted(true)
    } catch (e) {
      console.log(e)
    }
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Please enter your name'),
    email: Yup.string().email().required('Please enter an email address'),
    phone: Yup.string().required(
      'Please enter a phone number we can contact you on'
    ),
    company: Yup.string().required('Please enter your company name'),
    reason: Yup.string().required(
      'Please enter the reason you are contacting us'
    ),
    message: Yup.string().required('Please leave us a message'),
  })
  const initialValues = {
    name: '',
    email: '',
    phone: '',
    company: '',
    reason: '',
    message: '',
  }

  const headingMessage = () =>
    submitted ? 'Thanks!' : 'We’d love to hear from you'

  return (
    <>
      <SubHeader
        mb="5"
        textAlign={{
          xs: 'center',
          md: 'left',
        }}
      >
        {headingMessage()}
      </SubHeader>
      {!submitted && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isValid, dirty, isSubmitting }) => (
            <Form>
              <FormGroup>
                <Label htmlFor="name">Name</Label>
                <Field
                  as={Input}
                  name="name"
                  id="name"
                  type="text"
                  placeholder="John Smith"
                  required
                />
                <ErrorMessage component={Error} name="name" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="email">Email</Label>
                <Field
                  as={Input}
                  name="email"
                  id="email"
                  type="email"
                  placeholder="johnsmith@example.com"
                  required
                />
                <ErrorMessage component={Error} name="email" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="phone">Phone number</Label>
                <Field
                  as={Input}
                  name="phone"
                  id="phone"
                  type="text"
                  placeholder="07701 234 567"
                  required
                />
                <ErrorMessage component={Error} name="phone" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="company">Company</Label>
                <Field
                  as={Input}
                  name="company"
                  id="company"
                  type="text"
                  placeholder="Company Ltd"
                  required
                />
                <ErrorMessage component={Error} name="company" />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="reason">Reason</Label>
                <Field as={Select} name="reason" id="reason" required>
                  <option value="" disabled hidden>
                    Select your reason...
                  </option>
                  <option>Investing in one of our companies</option>
                  <option>Founder/Dev/Startup joining our studio</option>
                  <option>Startup wanting investment</option>
                  <option>Investor joining our Investment Club</option>
                  <option>Other</option>
                </Field>
                <ErrorMessage component={Error} name="reason" />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="message">Message</Label>
                <Field
                  as={TextArea}
                  name="message"
                  id="message"
                  rows="5"
                  placeholder="Enter your message..."
                  required
                />
                <ErrorMessage component={Error} name="message" />
              </FormGroup>

              <Emphasis color='red'>Please Note: If you are pitching to us for investment, instead of the form please email us on comeplay@horseplay.ventures with your pitch deck. Replies can take up to 6 weeks due to sheer volume, so please bear with us.</Emphasis>

              <SubmitBox>
                <Button
                  type="submit"
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  Come play
                </Button>
              </SubmitBox>
            </Form>
          )}
        </Formik>
      )}
      {submitted && (
        <Box>
          <Text>
            Thank you for getting in touch! We will be in touch shortly
          </Text>
        </Box>
      )}
    </>
  )
}

export default ContactUs
