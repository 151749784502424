import React from 'react'
import styled, { css } from '@xstyled/styled-components'

const StyledSelect = styled.select(
  () => css`
    font-size: 19px;
    margin-bottom: 8px;
    padding: 1rem;
    background-color: inputGray;
    display: block;
    width: 100%;
    border-width: 0;
    border-style: none;
    border-radius: 0;
    box-sizing: border-box;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    color: body;

    &:invalid {
      color: grey;
    }
  `
)

const Select = ({children, ...props}) => <StyledSelect {...props}>{children}</StyledSelect>

export default Select
